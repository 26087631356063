import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Style from "../../styles/home.module.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import layoutIconSvg from "../../assets/img/layout-svg.png";
import { useDispatch, useSelector } from "react-redux";
import { inputDrawingApi } from "../../store/home/layoutTable";
import { resetInputDrawingByClusterState } from "../../store/home/getInputDrawing";
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';

export default function DealortConstructions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputDrawing = useSelector((state) => state?.inputDrawingReducer?.result?.results?.data);
  const handleNavigate = (url) => {
    navigate(`/${url}`);
  }

  useEffect(() => {
    dispatch(inputDrawingApi());
    dispatch(resetInputDrawingByClusterState())
  }, [dispatch]);

  return (
    <Box>
      <Box className={Style?.headerSection}>
        <Typography className={Style?.cardHeading} variant="h6">
          Layout Management
        </Typography>
      </Box>

      <Stack sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2" style={{cursor:'pointer'}} onClick={()=>handleNavigate('layout')}>
              <Stack direction="row" alignItems="start" spacing={1} className="">
                <ViewComfyOutlinedIcon sx={{ mr: 1.5 }}/>
                <Box sx={{display:"block"}}>
                  <Typography className="card2Content" >Layout</Typography>
                  <Typography className={Style.cardValue} variant="h5">
                    {inputDrawing?.length}
                    </Typography>
                </Box>
              </Stack>
            </Card>
          </Grid>
          
        </Grid>
      </Stack>
    </Box>
  );
}
