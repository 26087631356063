import React from "react";
import { Box, Button, Grid, OutlinedInput, Tooltip, Typography, TextareaAutosize, Card } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInputDrawingByClusterIdApi } from "../../store/home/getInputDrawing";
import { convertToFormData } from "../ConvertToFormData";
import { sendToKin } from "../../store/home/postToKin";
import Feedback from "../reusable/Feedback";
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { handleDownload } from "../reusable/downloadFile";
import { showToast } from "../../toast/toast";
import DoneIcon from '@mui/icons-material/Done';
export default function InputDrawing({ dealorOutletId }) {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [approved, setApproved] = useState(false);
    const handleOpen = (id) => setOpen(true);
    const handleClose = () => setOpen(false);
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    const getInputDrawing = async () => {
        const formD = {
            'dealor_outlet_id': dealorOutletId
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(getInputDrawingByClusterIdApi(data));
            setData(response?.payload?.data);
        } catch (error) {
            
        }
    }

    
    const postToKin = async () => {
        const formData = convertToFormData({ 'form_id': formId?.data?.id });
        try {
            const response = await dispatch(sendToKin(formData));
            showToast(response?.payload?.message, 1);
            if (response?.payload?.status) {
                setApproved(true);
            }
        } catch (error) {
            console.error("Error in sending data to KIA:", error);
        }
    };

    useEffect(() => {
        getInputDrawing();
    }, []);

    useEffect(() => {
        if(formId){
            setApproved(formId?.data?.send_to_inn===true ? true : false);
        }
        
    },[formId])
    return (
        <>
            <Box className="h-100 d-flex flex-column justify-content-between">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <Typography variant="h6" className="fs-17  fw-400">Input Drawing</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                            <Box sx={{ width: "213px" }}>
                                {data?.input_pdf?.map((schematicLayoutData) => (
                                    <>
                                        <Box className="upload-box bg-white text-center">
                                            <Box className="p-relative">
                                                <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`} >
                                                    <img width={40} height={40} src={pdf} className="my-auto" />
                                                    <Typography>
                                                        {'PDF File'}
                                                    </Typography>
                                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </>
                                ))}
                            </Box>
                            <Box sx={{ width: "213px" }}>
                                {data?.input_cad?.map((schematicLayoutData) => (
                                    <>
                                        <Box className="upload-box bg-white text-center">
                                            <Box className="p-relative">
                                                <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`} >
                                                    <img width={40} height={40} src={cad} className="my-auto" />
                                                    <Typography>
                                                        {'CAD File'}
                                                    </Typography>
                                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </>
                                ))}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Grid container spacing={2} sx={{ marginTop: "50px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h6" className="fs-17 ">Sections Heights (in Ft.):</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Road to Start of Building</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.distance_from_road} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Building Total Height/ground level to Roof</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.building_height} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Ceiling Height</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.ceiling_height} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography variant="h6" className="fs-13 ">Beam Height</Typography>
                                <OutlinedInput disabled className="w-100 cstm-inputfield" placeholder={data?.beam_height} />
                            </Grid>
                        </Grid>

                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Attach Photos (Long Shot & Clear Image Required):</Typography>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                                <Typography variant="h6" className="fs-13 w-100" sx={{ marginTop: "22px" }}>Interior Photos</Typography>
                                {data?.layout_interior?.map((interiorPhotos) => {
                                    return (
                                        <Box sx={{ width: "213px" }}>
                                            <>
                                                <img className="border-radious-14" style={{ width: "198px", height: '181px' }}
                                                    src={`${process.env.REACT_APP_BASE_URL}${interiorPhotos?.photos}`}
                                                />
                                                <Box className="text-center justify-center d-flex" sx={{ gap: "7px", marginTop: "15px" }}>
                                                    <Box className="my-auto">
                                                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.639 0.943359H7.414V1.66811H9.76888L6.67578 4.77787L7.18527 5.29042L10.2787 2.18051V4.54856H10.9993V1.30573L10.639 0.943359Z" fill="#05141F" />
                                                            <path d="M3.81345 7.6543L0.720633 10.7645V8.3963H0V11.6397L0.360316 12.0021H3.58472V11.2773H1.22998L4.32308 8.16684L3.81345 7.6543Z" fill="#05141F" />
                                                            <path d="M0.72121 4.54856L0.720777 2.17616L3.81316 5.291L4.32308 4.77874L1.23473 1.66811H3.58529V0.943359H0.360316L0 1.30588L0.000576602 4.54871L0.72121 4.54856Z" fill="#05141F" />
                                                            <path d="M10.2779 10.7586L7.18498 7.65234L6.67578 8.16518L9.77277 11.2755L7.41328 11.276L7.41342 12.0007L10.6384 12.0001L10.9986 11.6377V8.39492H10.2779V10.7586Z" fill="#05141F" />
                                                        </svg>
                                                    </Box>
                                                    <Button className="fs-12 link-btn" sx={{ fontSize: "12px !important" }}
                                                        onClick={() => {
                                                            const imageUrl = `${process.env.REACT_APP_BASE_URL}${interiorPhotos?.photos}`;
                                                            const newWindow = window.open();
                                                            newWindow.document.write(`<img src="${imageUrl}" alt="Full View" style="width:100%;height:auto;">`);
                                                            newWindow.document.close();
                                                        }}
                                                    >
                                                    Full View</Button>

                                                </Box>
                                            </>

                                        </Box>
                                    )
                                }
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                                <Typography variant="h6" className="fs-13 w-100" sx={{ marginTop: "22px" }}>Exterior Photos</Typography>
                                {data?.layout_exterior?.map((exteriorPhotos, index) => {
                                    return (
                                        <Box sx={{ width: "213px" }} key={index}>
                                            <>
                                                <img className="border-radious-14" style={{ width: "198px", height: '181px' }}
                                                    src={`${process.env.REACT_APP_BASE_URL}${exteriorPhotos?.photos}`}
                                                />
                                                <Box className="text-center justify-center d-flex" sx={{ gap: "7px", marginTop: "15px" }}>
                                                    <Box className="my-auto">
                                                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.639 0.943359H7.414V1.66811H9.76888L6.67578 4.77787L7.18527 5.29042L10.2787 2.18051V4.54856H10.9993V1.30573L10.639 0.943359Z" fill="#05141F" />
                                                            <path d="M3.81345 7.6543L0.720633 10.7645V8.3963H0V11.6397L0.360316 12.0021H3.58472V11.2773H1.22998L4.32308 8.16684L3.81345 7.6543Z" fill="#05141F" />
                                                            <path d="M0.72121 4.54856L0.720777 2.17616L3.81316 5.291L4.32308 4.77874L1.23473 1.66811H3.58529V0.943359H0.360316L0 1.30588L0.000576602 4.54871L0.72121 4.54856Z" fill="#05141F" />
                                                            <path d="M10.2779 10.7586L7.18498 7.65234L6.67578 8.16518L9.77277 11.2755L7.41328 11.276L7.41342 12.0007L10.6384 12.0001L10.9986 11.6377V8.39492H10.2779V10.7586Z" fill="#05141F" />
                                                        </svg>
                                                    </Box>
                                                    <Button className="fs-12 link-btn" sx={{ fontSize: "12px !important" }}
                                                     onClick={() => {
                                                        const imageUrl = `${process.env.REACT_APP_BASE_URL}${exteriorPhotos?.photos}`;
                                                        const newWindow = window.open();
                                                        newWindow.document.write(`<img src="${imageUrl}" alt="Full View" style="width:100%;height:auto;">`);
                                                        newWindow.document.close();
                                                    }}
                                                     >
                                                     Full View</Button>

                                                </Box>
                                            </>


                                        </Box>)
                                }
                                )}
                            </Box>
                        </Grid>
                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Comment (if any)</Typography>
                        <TextareaAutosize disabled style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }} className="bg-white" minRows={6} placeholder={data?.comment} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{ gap: "23px" }}>
                        {/* {data?.approve_by_innoceans === true ? 
                            <Button className="black-btn" style={{color: "green"}}><DoneIcon style={{ marginRight: "2px", marginTop: "-5px" }}/> Approved</Button>
                        :   
                           
                        } */}
                        {/* <Button className="black-btn" onClick={() => postToKin()}>Send TO kin</Button> */}

                         <Button
                            className="black-btn" 
                        
                            onClick={postToKin}
                            disabled={approved}
                        >
                            {approved ? "Approved" : "Send To Innocean"}
                            {approved && <DoneIcon sx={{ marginLeft: "5px" }} />}
                        </Button>

                        <Button className="white-btn" onClick={() => handleOpen()}>Send Feedback To Dealer</Button>
                    </Grid>
                </Grid>
            </Box>
            <Feedback open={open} handleClose={handleClose} id={data?.id} />
        </>
    );
}