import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Style from "../../styles/home.module.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import CategoryIcon from '@mui/icons-material/Category';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import InventoryIcon from '@mui/icons-material/Inventory';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useDispatch, useSelector } from "react-redux";
import { getDepartment } from "../../store/department/getDepartment";
import { getSupplierUserApi } from "../../store/supplierUser/getSupplierUser";
import { getCategoryApi } from "../../store/category/getCategory";
import { getInventoryApi } from "../../store/inventory";
export default function DealorApplications() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getAllDepartment = useSelector((state) => state?.getDepartmentsReducer?.result?.data);
  const getSupplierUser = useSelector((state) => state?.getSupplierUserSliceReducer?.result?.results?.data);
  const getAllCategory = useSelector((state) => state?.categoryReducer?.result);
  const {result:inventoryList}  = useSelector(state => state?.inventoryReducer);

  useEffect(() => {
    dispatch(getDepartment())
    dispatch(getSupplierUserApi())
    dispatch(getCategoryApi())
    dispatch(getInventoryApi())
},[dispatch])
  return (
    <Box>
      <Box className={Style?.headerSection}>
        <Typography className={Style?.cardHeading} variant="h6">
          Master Data
        </Typography>
      </Box>

      <Stack sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg={3} style={{ display: "flex"}}>
            <Card className={Style?.homeCard1} onClick={() => navigate("/master/department")} style={{ cursor: "pointer", width: "100%" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <ApartmentIcon/>

                <Stack>
                  <Typography className={Style.cardTitle} variant="p">
                    Department (KIA)
                  </Typography>
                  <Typography className={Style.cardValue} variant="h5">
                    {getAllDepartment?.length}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard2} onClick={() => navigate("/master/organization")} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <CorporateFareIcon/>
                 

                <Stack>
                  <Typography variant="p"  className={Style.cardTitle}>
                    Supplier Organization
                  </Typography>
                  <Typography variant="h5"  className={Style.cardValue}>
                    {getSupplierUser?.length}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard3} onClick={() => navigate("/master/material")} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
              <CategoryIcon/>

                <Stack>
                  <Typography variant="p" className={Style.cardTitle}>
                    Material Category
                  </Typography>
                  <Typography variant="h5" className={Style.cardValue}>
                    {getAllCategory?.length}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Card className={Style?.homeCard4} onClick={() => navigate("/master/inventory")} style={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="start" spacing={1.5}>
                <InventoryIcon/>

                <Stack>
                  <Typography variant="p" className={Style.cardTitle}>
                    Inventory Management
                  </Typography>
                  <Typography variant="h5" className={Style.cardValue}>
                    {inventoryList?.data?.length}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>

      {/* <Stack sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2">
              <Stack direction="row" spacing={1} className="card2Inner">
                <CheckCircleOutlineIcon />
                <Typography className="card2Content">All Groups</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2">
              <Stack direction="row" spacing={1} className="card2Inner">
                <CheckCircleOutlineIcon />
                <Typography className="card2Content">All Groups</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2">
              <Stack direction="row" spacing={1} className="card2Inner">
                <CheckCircleOutlineIcon />
                <Typography className="card2Content">All Groups</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2">
              <Stack direction="row" spacing={1} className="card2Inner">
                <CheckCircleOutlineIcon />
                <Typography className="card2Content">All Groups</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2">
              <Stack direction="row" spacing={1} className="card2Inner">
                <CheckCircleOutlineIcon />
                <Typography className="card2Content">All Groups</Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack> */}
    </Box>
  );
}
